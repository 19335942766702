import { gsap } from "gsap";

function recalculateWrapperHeight() {
  const $wrapper = document.querySelector(".main-wrapper");
  let height = window.innerHeight

  if (window.innerWidth >= 1024) {
    height += 42
  }

  $wrapper.setAttribute("style", `height: ${height}px`);
}

recalculateWrapperHeight();
window.addEventListener("resize", recalculateWrapperHeight);

const bgAnim = gsap.timeline({
  defaults: {
    ease: "linear",
  },
  yoyo: true,
  repeat: -1,
});

const colorSteps = [
  '#181516',
  '#52231a',
  '#895952',
  '#b29185',
  '#895952',
  '#52231a',
  '#181516',
]

colorSteps.forEach((color) => {
  bgAnim.to('.bg-anim', {
    backgroundColor: color,
    duration: 2,
    delay: 0.25,
  })
})
